export const StatusEnum = {
  ACTIVE: {
    key: 'active',
    value: 0
  },
  DISABLED: {
    key: 'disabled',
    value: 1
  },
  PAUSED: {
    key: 'paused',
    value: 2
  }
};

export const PrivacyEnum = {
  PUBLIC: {
    key: 'public',
    label: 'public_auto_approve', //label is switched becouse original naming was confusing
    sub:
      "Affiliates will be automatically approved after accepting the campaign's T&C",
    value: 0
  },
  PRE_MODERATION: {
    key: 'pre_moderation',
    label: 'pre_moderation',
    value: 1
  },
  PRIVATE: {
    key: 'private',
    label: 'private',
    value: 2
  },
  PUBLIC_AUTO_APPROVE: {
    key: 'public_auto_approve',
    label: 'public', //label is switched becouse original naming was confusing
    sub: 'The campaign is public for all publishers.',
    value: 3
  }
};

export const CampaignSettings = {
  GENERAL: 'general',
  TRACKING: 'tracking',
  PAYOUTS: 'payouts'
};

export const CampaignCreativeTypes = {
  BANNER: { value: 0, key: 'banner', hideInCreative: false },
  EMAIL: { value: 1, key: 'email', hideInCreative: true },
  XML: { value: 3, key: 'xml', hideInCreative: true },
  HTML: { value: 2, key: 'html', hideInCreative: false },
  LINK: { value: 4, key: 'link', hideInCreative: false },
  VIDEO: { value: 5, key: 'video', hideInCreative: false },
  COUPON: { value: 6, key: 'coupon', hideInCreative: false }
};

export const ClickSessionLifeSpanEnum = {
  ONE_DAY: { oid: 0, name: '1 Day' },
  ONE_WEEK: { oid: 1, name: '1 Week' },
  ONE_MONTH: { oid: 2, name: '1 Month' },
  ONE_YEAR: { oid: 3, name: '1 Year' },
  CUSTOM: { oid: 4, name: 'Custom' },
  UNLIMITED: { oid: 5, name: 'Unlimited' }
};

export const ColorStatusEnum = {
  ACTIVE: {
    color: 'green'
  },
  APPROVED: {
    color: 'green'
  },
  READY: {
    color: 'green'
  },
  REVISION: {
    color: 'grey'
  },
  TEST: {
    color: 'grey'
  },
  HOLD: {
    color: 'yellow'
  },
  PENDING: {
    color: 'yellow'
  },
  PAUSED: {
    color: 'yellow'
  },
  DRAFT: {
    color: 'yellow'
  },
  PROCESSING: {
    color: 'primary'
  },
  IN_PROGRESS: {
    color: 'primary'
  },
  DENIED: {
    color: 'red'
  },
  DECLINED: {
    color: 'red'
  },
  FAILED: {
    color: 'red'
  },
  INACTIVE: {
    color: 'red'
  },
  DISABLED: {
    color: 'red'
  }
};

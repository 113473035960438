import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import FormattedMessage from 'react-intl/lib/src/components/message';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Label from 'src/components/Label';
import ScopeGuard from 'src/components/guards/ScopeGuard';
import Iconify from 'src/components/iconify';
import { accountSelector } from 'src/selectors/selectors';
import { UserPermissionsEnum } from 'src/utils/enums/UserPermissionsEnum';
import {
  isUrlDefaultDomain,
  openChatSupport,
  openDocs,
  openDocsV2,
  openPhoneSupportTab,
  stringToColor
} from 'src/utils/utils';
import { logout } from '../../../actions/userActions';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    fontSize: '13px'
  },
  popover: {
    width: 200
  }
}));

export function stringAvatar(name) {
  const initials =
    name
      .split(' ')
      .map(word => (word[0] ? String(word[0])?.toUpperCase() : ''))
      .join('') || 'U';

  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: initials,
    alt: initials
  };
}

function Account() {
  const [isOpen, setOpen] = useState(false);

  const { user } = useSelector(accountSelector);
  const { enqueueSnackbar } = useSnackbar();
  const fullName = useMemo(() => `${user?.firstName} ${user?.lastName}`, [
    user
  ]);
  const imgUrl = useMemo(
    () =>
      user?.profileImage
        ? process.env.REACT_APP_PROFILE_PIC_BASE_URL + user?.profileImage
        : '',
    [user?.profileImage]
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isDefaultDomain = useMemo(() => isUrlDefaultDomain(), []);

  const handleLogout = async () => {
    try {
      handleClose();
      dispatch(logout(true));
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  const handleNavigate = async path => {
    handleClose();
    history.push(path);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        {!!imgUrl ? (
          <Avatar
            {...stringAvatar(fullName)}
            className={classes.avatar}
            src={imgUrl}
          />
        ) : (
          <Avatar {...stringAvatar(fullName)} className={classes.avatar} />
        )}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <ScopeGuard scope={UserPermissionsEnum.BILLING.value}>
          <MenuItem onClick={() => handleNavigate('/app/management/billing')}>
            <ListItemIcon>
              <Iconify icon="material-symbols:shopping-cart-outline" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage id="billing" />
            </ListItemText>
          </MenuItem>
        </ScopeGuard>
        {isDefaultDomain && (
          <>
            <MenuItem
              onClick={() => handleNavigate('/app/management/contact-us')}
            >
              <ListItemIcon>
                <Iconify icon="material-symbols:contact-support-outline" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="contact_us" />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={openPhoneSupportTab}>
              <ListItemIcon>
                <Iconify icon="material-symbols:support-agent-rounded" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="phone_support" />
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={openChatSupport}>
              <ListItemIcon>
                <Iconify icon="material-symbols:contact-support-outline-rounded" />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="chat_support" />
              </ListItemText>
            </MenuItem>
          </>
        )}
        <Divider />
        {/* <MenuItem onClick={openDocs}>
          <ListItemIcon>
            <Iconify icon="tabler:api" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="API" />
          </ListItemText>{' '}
          <Typography variant="body2" color="text.secondary">
            <Label color="warning">DEPRECATED</Label>
          </Typography>
        </MenuItem> */}
        <MenuItem onClick={openDocsV2}>
          <ListItemIcon>
            <Iconify icon="tabler:api" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="API" /> V2
          </ListItemText>
          <Typography variant="body2" color="text.secondary">
            <Label color="info">New</Label>
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('https://help.tracknow.io/', '_blank').focus();
          }}
        >
          <ListItemIcon>
            <Iconify icon="oui:documentation" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="documentation" />
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleNavigate('/app/management/settings')}>
          <ListItemIcon>
            <Iconify icon="material-symbols:settings" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="settings" />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Iconify icon="material-symbols:logout" />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id="logout" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;

// eslint-disable-next-line import/prefer-default-export
export const PaymentTypeEnum = {
  CPS: {
    oid: 0,
    name: 'CPS',
    label: 'CPS (%)',
    symbol: '%',
    isBroker: false,
    color: 'green'
  },
  CPA: {
    oid: 1,
    name: 'CPA',
    label: 'CPA (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'black'
  },
  PPC: {
    oid: 2,
    name: 'PPC',
    label: 'PPC',
    symbol: undefined,
    isBroker: false,
    color: 'warning'
  },
  CPM: {
    oid: 3,
    name: 'CPM',
    label: 'CPM (Per 1K views)',
    symbol: undefined,
    isBroker: false
  },

  LIFETIME: {
    oid: 4,
    name: 'LIFETIME',
    label: 'Lifetime',
    symbol: '%',
    isBroker: false,
    color: 'green'
  },
  REV_SHARE: {
    oid: 5,
    name: 'REV_SHARE',
    label: 'Revshare', // if change change in casino en also
    symbol: '%',
    isBroker: true,
    color: 'purple'
  },
  CPA_QUALIFICATION: {
    oid: 6,
    name: 'CPA_QUALIFICATION',
    label: 'CPA Qualification', // if change change in casino en also
    symbol: undefined,
    isBroker: true,
    color: 'purple'
  },
  FTD: {
    oid: 7,
    name: 'FTD',
    label: 'FTD (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'red'
  },
  DEP: {
    oid: 8,
    name: 'DEP',
    label: 'DEP (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'red'
  },
  CPL: {
    oid: 9,
    name: 'CPL',
    label: 'CPL (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'red'
  },
  CPI: {
    oid: 10,
    name: 'CPI',
    label: 'CPI (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'success'
  },
  REG: {
    oid: 11,
    name: 'REG',
    label: 'REG (Fixed)',
    symbol: undefined,
    isBroker: false,
    useMinCpa: true,
    color: 'black'
  },
  CPM_LIKE: {
    oid: 12,
    name: 'CPM_LIKE',
    label: 'CPM (Per 1k likes)',
    symbol: undefined,
    isBroker: false,
    isSocial: true
  },
  CPM_COMMENT: {
    oid: 13,
    name: 'CPM_COMMENT',
    label: 'CPM (Per 1k comments)',
    symbol: undefined,
    isBroker: false,
    isSocial: true
  },
  CPM_STORY: {
    oid: 14,
    name: 'CPM_STORY',
    label: 'CPA (Per 1 story)',
    symbol: undefined,
    isBroker: false,
    isSocial: true
  },
  CPM_POST: {
    oid: 15,
    name: 'CPM_POST',
    label: 'CPA (Per 1 post)',
    symbol: undefined,
    isBroker: false,
    isSocial: true
  },
  CPA_FTD: {
    oid: 16,
    name: 'CPA_FTD',
    label: 'CPA FTD (%)',
    symbol: '%',
    isBroker: true,
    color: 'red'
  },
  CPA_DEPOSIT: {
    oid: 20,
    name: 'CPA_DEPOSIT',
    label: 'CPA Deposit (%)',
    symbol: '%',
    isBroker: true,
    color: 'black'
  },
  CPA_LEAD: {
    oid: 17,
    name: 'CPA_LEAD',
    label: 'CPA LEAD',
    symbol: undefined
  },
  BONUS: {
    oid: 18,
    name: 'BONUS',
    label: 'Bonus (fixed)',
    symbol: undefined
  },
  LIFETIME_CPA: {
    oid: 19,
    name: 'LIFETIME_CPA',
    label: 'Lifetime (fixed)',
    symbol: undefined,
    useMinCpa: true,
    color: 'success'
  }
};

import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider
} from '@mui/styles';
import { useSelector } from 'react-redux';
import 'src/assets/css/generalOnly.css';
import Auth from 'src/components/Auth';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import Routes from './Routes';
import GlobalErrorModal from './components/GlobalErrorModal';
import useEnableScript from './hooks/useEnableScript';
import useGoogleTagManager from './hooks/useGoogleTagManager';
import DialogProvider from './providers/DialogProvider';
import { brandingSelector } from './selectors/selectors';
import { createCustomTheme, getTheme } from './theme';
import PermissionsUtils from './utils/security/PermissionsUtils';

import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect } from 'react';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import GlobalRecaptchaModal from './components/GlobalRecaptchaModal';
import useChatScript from './hooks/useChatScript';
import { SocketProvider } from './hooks/useSocket';
import ErrorBoundary from './providers/ErrorBoundary';
import RecaptchaProvider from './providers/RecaptchaProvider';
import RTL from './providers/RtlProvider';
import TranslationProvider from './providers/TranslationProvider';
import TwoFactorProvider from './providers/TwoFactorProvider';
import CustomCSSBaseline from './theme/CustomCssBaseline';
import { fetchInitialDataIfNotDefaultDomain } from './utils/utils';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE);

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { settings } = useSettings();

  const primaryColor = useSelector(
    state => brandingSelector(state)?.primaryColor
  );

  const secondaryColor = useSelector(
    state => brandingSelector(state)?.secondaryColor
  );

  const primaryDarkColor = useSelector(
    state => brandingSelector(state)?.primaryDarkColor
  );

  const secondaryDarkColor = useSelector(
    state => brandingSelector(state)?.secondaryDarkColor
  );

  const useStylesLTR = makeStyles(() => {
    let t = getTheme(settings);
    return createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          fontFamily: 'Roboto, sans-serif'
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        },
        // '*::-webkit-scrollbar': {
        //   width: '1.3em',
        //   height: '0.9em'
        // },
        // '*::-webkit-scrollbar-track': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        // '*::-webkit-scrollbar-corner': {
        //   '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        // },
        // '*::-webkit-scrollbar-thumb': {
        //   backgroundColor: 'rgba(0,0,0,.1)',
        //   outline: '0px solid slategrey',
        //   borderRadius: 0
        // },
        'input::-ms-reveal, input::-ms-clear': {
          display: 'none'
        }
      }
    });
  });

  const useStylesRTL = makeStyles(() => {
    let t = getTheme(settings);
    return createStyles({
      '@global': {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
          fontFamily: 'Open Sans Hebrew, sans-serif'
        },
        html: {
          '-webkit-font-smoothing': 'antialiased',
          '-moz-osx-font-smoothing': 'grayscale',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%',
          width: '100%'
        },
        '#root': {
          height: '100%',
          width: '100%'
        },
        'input::-ms-reveal, input::-ms-clear': {
          display: 'none'
        }
      }
    });
  });

  if (settings.direction === 'ltr') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStylesLTR();
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStylesRTL();
  }
  useEnableScript(process.env.REACT_APP_ENABLE_KEY);
  useGoogleTagManager(process.env.REACT_APP_GTM_ID);
  useChatScript();

  useEffect(() => {
    fetchInitialDataIfNotDefaultDomain();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={createCustomTheme(
          settings,
          primaryColor,
          secondaryColor,
          primaryDarkColor,
          secondaryDarkColor
        )}
      >
        <StylesProvider jss={jss}>
          <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <Router history={history}>
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <TranslationProvider>
                  <RTL isRtl={settings.direction === 'rtl'}>
                    <DialogProvider>
                      <TwoFactorProvider>
                        <RecaptchaProvider>
                          <GlobalErrorModal />
                          <GlobalRecaptchaModal />
                          <PermissionsUtils />
                          <Auth>
                            <SocketProvider>
                              <ScrollReset />
                              {/* <CookiesNotification /> */}
                              {/* for now its has been disabled */}
                              {/* <SettingsNotification /> */}
                              <Routes />
                            </SocketProvider>
                          </Auth>
                          <CustomCSSBaseline />
                        </RecaptchaProvider>
                      </TwoFactorProvider>
                    </DialogProvider>
                  </RTL>
                </TranslationProvider>
              </ErrorBoundary>
            </Router>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;

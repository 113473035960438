import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { handleDayTime, handleTimeInFormat } from '../utils/utils';

function WithKeyboardDatePicker({
  value,
  field,
  labelKey,
  intl,
  minDate,
  minDateMessage,
  onDatePickerChange,
  helperText,
  includeTime = false,
  isStartDate = false,
  isEndDate = false,
  error,
  required = false,
  ...other
}) {
  const userTimeFormat = useSelector(
    state => state.account?.user?.personalization?.timeFormat
  );
  const dateFormat = handleTimeInFormat(userTimeFormat, !includeTime);

  const momentValue = !!value
    ? handleDayTime(moment(value), isStartDate, isEndDate)
    : null;
  return includeTime ? (
    <DateTimePicker
      {...other}
      sx={{ width: '100%' }}
      variant="inline"
      inputVariant="outlined"
      slotProps={{
        field: {
          clearable: true,
          helperText: error ? error : helperText,
          error: !!error,
          required: !!required
        }
      }}
      label={intl.formatMessage({ id: labelKey })}
      format={dateFormat}
      renderInput={props => (
        <TextField {...props} label={intl.formatMessage({ id: labelKey })} />
      )}
      value={momentValue}
      InputAdornmentProps={{ position: 'end' }}
      onChange={date => {
        handleDayTime(date, isStartDate, isEndDate);
        onDatePickerChange(date);
      }}
      minDate={minDate ? moment(minDate) : null}
      minDateMessage={minDateMessage}
    />
  ) : (
    <DatePicker
      {...other}
      sx={{ width: '100%' }}
      variant="inline"
      slotProps={{
        field: {
          clearable: true,
          helperText: error ? error : helperText,
          error: !!error,
          required: !!required
        }
      }}
      inputVariant="outlined"
      label={intl.formatMessage({ id: labelKey })}
      format={dateFormat}
      renderInput={props => (
        <TextField {...props} label={intl.formatMessage({ id: labelKey })} />
      )}
      value={momentValue}
      InputAdornmentProps={{ position: 'end' }}
      onChange={date => {
        onDatePickerChange(handleDayTime(date, isStartDate, isEndDate));
      }}
      minDate={minDate ? moment(minDate) : null}
      minDateMessage={minDateMessage}
    />
  );
}

WithKeyboardDatePicker.propTypes = {
  value: PropTypes.any,
  intl: PropTypes.any,
  field: PropTypes.string,
  labelKey: PropTypes.string,
  minDate: PropTypes.any,
  minDateMessage: PropTypes.string,
  onDatePickerChange: PropTypes.func
};

export default injectIntl(WithKeyboardDatePicker);

export default {
  conversions: 'Daily Commissions',
  conversion: 'Daily Commission',
  last_conversions: 'Latest Daily Commissions',
  order_id: 'Commission Id',
  goal_value: 'Spread Group (Regex)',
  volume: 'Lots',
  users: 'Clients',
  campaigns: 'Campaigns',
  forex: 'Analytics Reports',
  user_id: 'Client ID',
  campaign: 'Campaign',
  financial_stats: 'Stats',
};

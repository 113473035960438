export default {
  financial_users: 'Players',
  account_id: 'User ID',
  financial_stats: 'iGaming Stats',
  financial_transactions: 'Transactions',
  forex: 'Analytics Reports',
  broker_commission: 'Brand Commission',
  'Revshare (Financial)': 'Revshare (iGaming)',
  'CPA Qualification (Financial)': 'CPA Qualification (iGaming)',
  min_amount: 'Baseline',
  users: 'Players',
  last_trade_date: 'Last Activity Date',
  conversions: 'Daily Commissions',
  conversion: 'Daily Commission',
  last_conversions: 'Latest Daily Commissions',
  user_id: 'Player Id',
  order_id: 'Commission Id',
  conversions_count: 'Daily Commissions Count',
  financialUserBalance: 'Player Balance',
  min_net_deposit: 'Base Line',
  campaigns: 'Campaigns',
  campaign: 'Campaign'
};
